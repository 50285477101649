import { render, staticRenderFns } from "./TransactionStatus.vue?vue&type=template&id=fa68c594&scoped=true"
import script from "./TransactionStatus.vue?vue&type=script&lang=js"
export * from "./TransactionStatus.vue?vue&type=script&lang=js"
import style0 from "./TransactionStatus.vue?vue&type=style&index=0&id=fa68c594&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa68c594",
  null
  
)

export default component.exports