<template>
  <div>
    <Header :filterConditions="filterConditions" />
    <PaginationNumbers
      :key="transactionTableUpdater"
      v-if="totalPages > 0 && activeTransactionsTab !== 'brokers'"
      class="pagination-numbers"
      :total-pages="totalPages"
      :total="totalTransactions"
      :per-page="limit"
      :current-page="currentPage"
      @pagechanged="onPageChange"
      :style="'margin-left: 564px; margin-bottom: -24px'"
    />
    <Content
      :transactions="filteredTransactions"
      :all-transactions="filteredAllPagesTransactions"
      :brokersTransactions="filteredBrokersTransactions"
      :brokerTransactionsData="transactionsData"
      :loaded="loaded"
      @updateTransaction="updateTransaction"
      :selectedSort="selectedSort"
      @sortTransactions="sortData"
      @getBrokerTransactions="getBrokersTransactions"
      @clearFilters="clearFilters"
      :update-content="transactionUpdater"
    />
    <PaginationNumbers
      :key="transactionTableUpdater + 'updater'"
      v-if="totalPages > 0 && activeTransactionsTab !== 'brokers'"
      class="pagination-numbers"
      :total-pages="totalPages"
      :total="totalTransactions"
      :per-page="limit"
      :current-page="currentPage"
      @pagechanged="onPageChange"
      :style="'margin-top: -32px; margin-bottom: 32px; margin-left: 564px'"
    />
  </div>
</template>

<script>
import Header from "../../components/Transactions/Header.vue";
import Content from "../../components/Transactions/Content.vue";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import PaginationNumbers from "@/components/common/PaginationNumbers";

export default {
  name: "Transactions",
  components: {
    Header,
    Content,
    PaginationNumbers,
  },
  data() {
    return {
      allTransactions: [],
      filteredTransactions: [],
      filteredBrokersTransactions: [],
      transactionsData: {},
      filterConditions: [],
      filteredAllPagesTransactions: [],
      loaded: false,
      transactionUpdater: 0,
      totalPages: 0,
      currentPage: 1,
      limit: 50,
      totalTransactions: 0,
      transactionTableUpdater: 0,
      lastTransactionPage: 1,
      conditionsObj: {},
      selectedSort: {
        sortField: null,
        sortOrder: null,
      },
    };
  },
  async created() {
    await this.initializeFilters();
  },
  mounted() {
    if (this.$route.query.page)
      this.currentPage = parseInt(this.$route.query.page);
    //this.refresh(true)
  },
  computed: {
    ...mapGetters([
      "provisionalFiltersUpdater",
      "provisionalFilters",
      "filters",
      "user",
      "users",
      "activeTransactionsTab",
    ]),
  },
  methods: {
    ...mapActions(["setProvisionalFilter", "setActiveTransactionsTab"]),
    async initializeFilters() {
      const savedViewId = this.$route.params.savedViewId;
      const activeTab = this.$route.params.activeTab;
      let brokersInitFilterCondition;
      let initFilterCondition;
      /* this.filters["transactions"].find(
          (item) => item._id === '4'
      ).conditions.find((condition) => condition.name === 'tag').value = 'this_month'; */

      //NEED HERE TO UPDATE ALL FILTERS EVEN SAVED...
      this.filters["transactions"].forEach((_, index) => {
        if (
          !this.filters["transactions"][index].conditions.find(
            (item) => item.title === "TransactionDate"
          )
        ) {
          this.filters["transactions"][index].conditions.push({
            name: "transactionDate",
            title: "TransactionDate",
            value: "this_month",
            valueTitle: "",
          });
        }
      });

      this.filters["brokerTransactions"].forEach((_, index) => {
        if (
          !this.filters["brokerTransactions"][index].conditions.find(
            (item) => item.title === "TransactionDate"
          )
        ) {
          this.filters["brokerTransactions"][index].conditions.push({
            name: "transactionDate",
            title: "TransactionDate",
            value: "this_month",
            valueTitle: "",
          });
        }
      });

      this.filters["transactions"].forEach((_, index) => {
        if (
          !this.filters["transactions"][index].conditions.find(
            (item) => item.title === "TransactionDateFrom"
          )
        ) {
          this.filters["transactions"][index].conditions.push(
            {
              name: "transactionDateFrom",
              title: "TransactionDateFrom",
              value: "",
              valueTitle: "",
            },
            {
              name: "transactionDateTo",
              title: "TransactionDateTo",
              value: "",
              valueTitle: "",
            }
          );
        }
      });

      this.filters["brokerTransactions"].forEach((_, index) => {
        if (
          !this.filters["brokerTransactions"][index].conditions.find(
            (item) => item.title === "TransactionDateFrom"
          )
        ) {
          this.filters["brokerTransactions"][index].conditions.push(
            {
              name: "transactionDateFrom",
              title: "TransactionDateFrom",
              value: "",
              valueTitle: "",
            },
            {
              name: "transactionDateTo",
              title: "TransactionDateTo",
              value: "",
              valueTitle: "",
            }
          );
        }
      });

      this.filters["transactions"][0].conditions.find((condition) => condition.name === "transactionDate").value = "this_month";

      if (activeTab && savedViewId) {
        this.setActiveTransactionsTab(activeTab);
        if (activeTab === "transactions") {
          initFilterCondition = this.filters["transactions"].find(
            (item) => item._id === savedViewId
          ).conditions;
          let brokersFilter = this.filters["brokerTransactions"].find(
            (item) => item.isDefault
          );
          brokersInitFilterCondition = brokersFilter.conditions;
          const brokerField = brokersInitFilterCondition.find(
            (condition) => condition.name === "brokerId"
          );

          if (this.user.access.accessLevel === "company_admin") {
            if (brokersFilter.name === "allValues") {
              brokerField.value = null;
              brokersFilter.filterTitle = "AllTransactions";
            }
          } else {
            if (brokersFilter.name === "allValues") {
              brokerField.value = {
                type: "user",
                value: this.user._id,
              };
              brokersFilter.filterTitle = "MyTransactions";
            }
          }
        } else {
          brokersInitFilterCondition = this.filters["brokerTransactions"].find(
            (item) => item._id === savedViewId
          ).conditions;
          let initFilter = this.filters["transactions"].find(
            (item) => item.isDefault
          );
          initFilterCondition = initFilter.conditions;
          const brokerField = initFilterCondition.find(
            (condition) => condition.name === "brokerId"
          );
          if (this.user.access.accessLevel === "company_admin") {
            if (initFilter.name === "allValues") {
              brokerField.value = null;
              initFilter.filterTitle = "AllTransactions";
            }
          } else {
            if (initFilter.name === "allValues") {
              brokerField.value = {
                type: "user",
                value: this.user._id,
              };
              initFilter.filterTitle = "MyTransactions";
            }
          }
        }
      } else {
        const brokerFilter = this.filters["brokerTransactions"].find(
          (item) => item.isDefault
        );
        brokersInitFilterCondition = brokerFilter.conditions;
        const brokerField = brokersInitFilterCondition.find(
          (condition) => condition.name === "brokerId"
        );
        const initFilter = this.filters["transactions"].find(
          (item) => item.isDefault
        );
        initFilterCondition = initFilter.conditions;
        const brokerField2 = initFilterCondition.find(
          (condition) => condition.name === "brokerId"
        );

        if (this.user.access.accessLevel === "company_admin") {
          if (brokerFilter.name === "allValues") {
            brokerField.value = null;
            brokerFilter.filterTitle = "AllTransactions";
          }
          if (initFilter.name === "allValues") {
            brokerField2.value = null;
            initFilter.filterTitle = "AllTransactions";
          }
        } else {
          if (brokerFilter.name === "allValues") {
            brokerField.value = {
              type: "user",
              value: this.user._id,
            };
            brokerFilter.filterTitle = "MyTransactions";
          }
          if (initFilter.name === "allValues") {
            brokerField2.value = {
              type: "user",
              value: this.user._id,
            };
            initFilter.filterTitle = "MyTransactions";
          }
        }
      }
      if (!brokersInitFilterCondition) {
        brokersInitFilterCondition =
          this.filters["brokerTransactions"][0].conditions;
      }
      if (!initFilterCondition) {
        initFilterCondition = this.filters["transactions"][0].conditions;
      }
      if (this.activeTransactionsTab === "brokers") {
        this.filterConditions = brokersInitFilterCondition;
      } else {
        this.filterConditions = initFilterCondition;
      }

      await this.setProvisionalFilter({
        filterName: "brokerTransactions",
        conditions: brokersInitFilterCondition,
      });
      await this.setProvisionalFilter({
        filterName: "transactions",
        conditions: initFilterCondition,
      });
    },
    async clearFilters() {
      let filterName =
        this.activeTransactionsTab === "brokers"
          ? "brokerTransactions"
          : "transactions";
      let conditions = this.filters[filterName].find(
        (item) => item.isDefault
      ).conditions;
      if (!conditions) {
        conditions = this.filters[filterName][0].conditions;
      }
      await this.setProvisionalFilter({
        filterName: filterName,
        conditions: conditions,
      });
    },
    async sortData(event) {
      this.selectedSort = {
        sortField: event.sortField,
        sortOrder: event.sortOrder,
      };
      await this.refresh(true);
    },
    async refresh(isLanding) {
      this.loaded = false;

      if (!isLanding) {
        this.currentPage = 1;
        await this.$router.push({
          name: "Transactions",
          query: {
            page: this.currentPage,
          },
        });
      }
      await this.getAllTransactions();
      /* if (this.activeContactsTab === "organizations") {
        await this.initOrganizations();
      } else {
        await this.initCustomers();
      } */
      this.loaded = true;
    },
    async getAllTransactions() {
      this.conditionsObj = this.provisionalFilters["transactions"].reduce(
        (a, v) => ({ ...a, [v.name]: v.value }),
        {}
      );
      const localStorageConditions = JSON.parse(localStorage.getItem("vuex"));

      let data = {
        lang: localStorageConditions.a.language,
        page: this.$route.query.page,
        filters: this.conditionsObj,
        limit: this.limit,
        sortField: this.selectedSort.sortField,
        sortOrder: this.selectedSort.sortOrder,
      };

      const response = await axios.post(
        "/api/transactions/filtered/paginated/" + data.page,
        data
      );
      this.filterConditions = this.provisionalFilters["transactions"];
      this.filteredTransactions = response.data.transactions;
      this.totalTransactions = response.data.filteredTransactionsCount;
      this.filteredAllPagesTransactions = response.data.allTransactions;
      await this.totalPagesCounter();
      this.transactionTableUpdater++;
    },
    async getBrokersTransactions() {
      const response = await axios.post(
        "/api/transactions/brokers",
        this.provisionalFilters["brokerTransactions"]
      );
      this.filterConditions = this.provisionalFilters["brokerTransactions"];
      this.transactionsData.totalBrokerageFee =
        response.data?.totalBrokerageFee;
      this.transactionsData.unpaidBrokerageFee =
        response.data?.unpaidBrokerageFee;
      this.filteredBrokersTransactions = response.data?.tableData;
    },
    async updateTransaction(updatedTransaction) {
      let response = await axios.patch(
        `/api/transaction-update/${updatedTransaction._id}`,
        updatedTransaction
      );

      if (response.status === 200) {
        this.toastSuccess(this.$t("TransactionUpdated"));
        this.allTransactions = this.allTransactions.map((transaction) =>
          transaction._id !== updatedTransaction._id
            ? transaction
            : updatedTransaction
        );
        this.filteredTransactions = this.filteredTransactions.map(
          (transaction) =>
            transaction._id !== updatedTransaction._id
              ? transaction
              : updatedTransaction
        );
        this.transactionUpdater++;
        await this.getAllTransactions();
      } else {
        this.toastError(this.$t("ErrorUpdating"));
      }
    },
    async onPageChange(page, notificationId) {
      await this.$router.push({
        name: "Transactions",
        query: {
          page: page,
          notificationId: notificationId,
        },
      });
      this.currentPage = page;
      window.scroll(0, 0);
      await this.refresh(true);
    },
    totalPagesCounter() {
      this.totalPages = Math.ceil(this.totalTransactions / this.limit);
    },
  },
  watch: {
    async provisionalFiltersUpdater() {
      this.loaded = false;
      if (
        this.activeTransactionsTab &&
        this.activeTransactionsTab === "brokers"
      ) {
        await this.getBrokersTransactions();
      } else {
        await this.getAllTransactions();
      }
      this.loaded = true;
    },
    async activeTransactionsTab(value) {
      this.loaded = false;

      if (value && value === "brokers") {
        await this.getBrokersTransactions();
      } else {
        await this.getAllTransactions();
      }
      this.loaded = true;
    },
    "$route.query.page": async function (newVal) {
      if (!newVal) return;
      this.currentPage = this.$route.query.page;
      //await this.getAllTransactions();

      /* if (
        this.$route.query.notificationId ||
        this.$route.params.notificationId
      ) {
        this.savedViewId = null;
        await this.initListingsProvisionalFilter();
      } else {
        this.clearListings();
        await this.initListings();
      } */
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-numbers {
  margin-left: 679px;
  width: 300px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  height: 64px;
  color: black;
}
</style>
