var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "transaction-overlay"
    }
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function ($event) {
        return _vm.closePanel($event);
      },
      expression: "($event) => closePanel($event)"
    }],
    staticClass: "transaction-sidepanel"
  }, [_c('div', {
    staticClass: "transaction-sidepanel-header"
  }, [_c('h4', [_vm._v(_vm._s(_vm.broker.firstName + " " + _vm.broker.lastName))])]), !_vm.loaded ? _c('div', {
    staticClass: "transaction-sidepanel-loader-container"
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm.loaded && _vm.brokerTransactions.length > 0 ? _c('div', {
    staticClass: "transaction-sidepanel-content"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Transactions")))]), _c('div', {
    staticClass: "transaction-sidepanel-cards"
  }, _vm._l(_vm.brokerTransactions, function (transaction, index) {
    return _c('div', {
      key: 'transaction-' + index,
      staticClass: "transaction-card"
    }, [_c('div', {
      class: 'content-small default-label-style ' + transaction.transactionType
    }, [_vm._v(" " + _vm._s(_vm.$t(transaction.transactionType)) + " ")]), _c('div', {
      staticClass: "content-normal",
      staticStyle: {
        "white-space": "nowrap",
        "text-overflow": "ellipsis",
        "width": "100%",
        "overflow": "hidden"
      }
    }, [_vm._v(" " + _vm._s(transaction.transactionName) + " ")]), _c('div', {
      staticClass: "content-semibold",
      staticStyle: {
        "white-space": "nowrap",
        "min-width": "72px",
        "width": "72px",
        "text-align": "left"
      }
    }, [_vm._v(" " + _vm._s(transaction.brokerageFee ? transaction.brokerageFee + " €" : "0 €") + " ")]), _vm.user.access.company._id !== '5p1EOo4yqjeiYCN3R16w' ? _c('div', {
      staticClass: "payment-status-button-container"
    }, [_c('div', {
      class: {
        'paid-label': transaction.status === 'paid',
        'not-paid-label': transaction.status === 'not_paid'
      },
      staticStyle: {
        "width": "fit-content"
      },
      attrs: {
        "id": 'broker-transaction-' + transaction._id
      },
      on: {
        "click": function ($event) {
          return _vm.openPaymentDropdown(transaction);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.formatStatus(transaction.status)) + " ")])]) : _vm._e()]);
  }), 0)]) : _c('div', {
    staticClass: "transaction-sidepanel-content"
  }, [_c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.$t("noTransactions")))])])]), _vm.paymentDropdownOpen ? _c('PaymentStatusDropdown', {
    attrs: {
      "position": _vm.paymentDropdownPosition,
      "transaction": _vm.selectedTransaction
    },
    on: {
      "closed": function ($event) {
        _vm.paymentDropdownOpen = false;
      },
      "statusChanged": _vm.updatePaymentStatus
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }