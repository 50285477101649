var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Header', {
    attrs: {
      "filterConditions": _vm.filterConditions
    }
  }), _vm.totalPages > 0 && _vm.activeTransactionsTab !== 'brokers' ? _c('PaginationNumbers', {
    key: _vm.transactionTableUpdater,
    staticClass: "pagination-numbers",
    style: 'margin-left: 564px; margin-bottom: -24px',
    attrs: {
      "total-pages": _vm.totalPages,
      "total": _vm.totalTransactions,
      "per-page": _vm.limit,
      "current-page": _vm.currentPage
    },
    on: {
      "pagechanged": _vm.onPageChange
    }
  }) : _vm._e(), _c('Content', {
    attrs: {
      "transactions": _vm.filteredTransactions,
      "all-transactions": _vm.filteredAllPagesTransactions,
      "brokersTransactions": _vm.filteredBrokersTransactions,
      "brokerTransactionsData": _vm.transactionsData,
      "loaded": _vm.loaded,
      "selectedSort": _vm.selectedSort,
      "update-content": _vm.transactionUpdater
    },
    on: {
      "updateTransaction": _vm.updateTransaction,
      "sortTransactions": _vm.sortData,
      "getBrokerTransactions": _vm.getBrokersTransactions,
      "clearFilters": _vm.clearFilters
    }
  }), _vm.totalPages > 0 && _vm.activeTransactionsTab !== 'brokers' ? _c('PaginationNumbers', {
    key: _vm.transactionTableUpdater + 'updater',
    staticClass: "pagination-numbers",
    style: 'margin-top: -32px; margin-bottom: 32px; margin-left: 564px',
    attrs: {
      "total-pages": _vm.totalPages,
      "total": _vm.totalTransactions,
      "per-page": _vm.limit,
      "current-page": _vm.currentPage
    },
    on: {
      "pagechanged": _vm.onPageChange
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }