<template>
  <div class="transaction-statuses content-small">
    <div :class="!status ? 'selected-transaction-status' : 'transaction-status'"
         @click="$emit('selectStatus', '')"
    >
      {{ $t("All") }}
    </div>
    <div :class="status === 'paid' ? 'selected-transaction-status' : 'transaction-status'"
         @click="$emit('selectStatus', 'paid')"
    >
      {{ $t("Paid") }}
    </div>
    <div :class="status === 'not_paid'  ? 'selected-transaction-status' : 'transaction-status'"
         @click="$emit('selectStatus', 'not_paid')"
    >
      {{ $t("NotPaid") }}
    </div>
  </div>

</template>

<script>
export default {
  name: "TransactionStatus",
  props: ["status"],
}
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.transaction-statuses {
  background: $grey500;
  border-radius: 8px;
  border: 1px solid $grey500;
  display: flex;
  gap: 1px;
  overflow: hidden;
}

.selected-transaction-status {
  background: $grey;
  padding: 8px;
}

.transaction-status {
  background: #fff;
  padding: 8px;
  cursor: pointer;
}

</style>