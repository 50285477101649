var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "transaction-statuses content-small"
  }, [_c('div', {
    class: !_vm.status ? 'selected-transaction-status' : 'transaction-status',
    on: {
      "click": function ($event) {
        return _vm.$emit('selectStatus', '');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("All")) + " ")]), _c('div', {
    class: _vm.status === 'paid' ? 'selected-transaction-status' : 'transaction-status',
    on: {
      "click": function ($event) {
        return _vm.$emit('selectStatus', 'paid');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Paid")) + " ")]), _c('div', {
    class: _vm.status === 'not_paid' ? 'selected-transaction-status' : 'transaction-status',
    on: {
      "click": function ($event) {
        return _vm.$emit('selectStatus', 'not_paid');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NotPaid")) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }