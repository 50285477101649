<template>
  <div id="transaction-overlay">
    <div
        class="transaction-sidepanel"
        v-click-outside="($event) => closePanel($event)"
    >
      <div class="transaction-sidepanel-header">
        <h4>{{ broker.firstName + " " + broker.lastName }}</h4>
      </div>
      <div v-if="!loaded" class="transaction-sidepanel-loader-container">
        <div class="loader"></div>
      </div>
      <div v-else-if="loaded && brokerTransactions.length > 0" class="transaction-sidepanel-content">
        <h5>{{ $t("Transactions") }}</h5>
        <div class="transaction-sidepanel-cards">
          <div class="transaction-card" v-for="(transaction, index) of brokerTransactions"
               :key="'transaction-' + index">
            <div :class="'content-small default-label-style ' + transaction.transactionType">
              {{ $t(transaction.transactionType) }}
            </div>
            <div class="content-normal"
                 style="white-space: nowrap; text-overflow: ellipsis; width: 100%; overflow: hidden">
              {{ transaction.transactionName }}
            </div>
            <div style="white-space: nowrap; min-width: 72px; width: 72px; text-align: left" class="content-semibold">
              {{ transaction.brokerageFee ? transaction.brokerageFee + " €" : "0 €" }}
            </div>
            <div v-if="user.access.company._id !== '5p1EOo4yqjeiYCN3R16w'" class="payment-status-button-container">
              <div @click="openPaymentDropdown(transaction)" style="width: fit-content" v-bind:class="{
                'paid-label': transaction.status === 'paid',
                'not-paid-label': transaction.status === 'not_paid',}" :id="'broker-transaction-' + transaction._id">
                {{ formatStatus(transaction.status) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="transaction-sidepanel-content">
        <p class="content-normal">{{ $t("noTransactions") }}</p>
      </div>
    </div>
    <PaymentStatusDropdown
        v-if="paymentDropdownOpen"
        :position="paymentDropdownPosition"
        :transaction="selectedTransaction"
        @closed="paymentDropdownOpen = false"
        @statusChanged="updatePaymentStatus"
    />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import PaymentStatusDropdown from "@/components/Transactions/PaymentStatusDropdown";
import {mapGetters} from "vuex";

export default {
  name: "TransactionBrokerSidepanel",
  props: ["broker"],
  components: {PaymentStatusDropdown},
  data() {
    return {
      brokerTransactions: [],
      selectedTransaction: null,
      paymentDropdownPosition: null,
      paymentDropdownOpen: false,
      loaded: false
    }
  },
  created() {
    this.getBrokerTransactions()
  },
  computed: {
    ...mapGetters(["user", "provisionalFilters"])
  },
  methods: {
    async getBrokerTransactions() {
      const response = await axios.post(`/api/transactions/broker/filtered/${this.broker.brokerId}`, this.provisionalFilters["brokerTransactions"]);
      this.brokerTransactions = response.data
      this.loaded = true;
    },
    async updatePaymentStatus() {
      this.selectedTransaction.status = this.selectedTransaction.status === 'not_paid' ? 'paid' : 'not_paid';
      this.selectedTransaction.paidDate = this.selectedTransaction.status === 'not_paid' ? null : moment().format();
      const response = await axios.patch(`/api/transaction-update/${this.selectedTransaction._id}`, this.selectedTransaction);
      if (response && response.status === 200) {
        this.toastSuccess(this.$t("TransactionUpdated"));
        this.$emit("transactionUpdated");
      }
      this.selectedTransaction = null;
      this.paymentDropdownOpen = false;
    },
    openPaymentDropdown(transaction) {
      if (!this.hasAccess(transaction)) return;
      let button = document.getElementById('broker-transaction-' + transaction._id);
      const rect = button.getBoundingClientRect();
      this.paymentDropdownPosition = {};
      this.paymentDropdownPosition.right = window.innerWidth - rect.right;
      this.paymentDropdownPosition.top = rect.top + 24;
      this.selectedTransaction = transaction;
      this.paymentDropdownOpen = true;
    },
    closePanel(event) {
      if (event.target.id !== "transaction-overlay") return;
      this.$emit("closePanel")
    },
    formatStatus(status) {
      if (status === "not_paid") {
        return this.$t("NotPaid");
      } else if (status === "paid") {
        return this.$t("Paid");
      }
    },
  }
}
</script>

<style scoped>
#transaction-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  width: unset;
  left: 0;
  background: #0000001a;
}

.transaction-sidepanel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  bottom: 0;
  background-color: #ffffff;
  z-index: 9;
  width: 640px;
  border-left: 1px solid #E6E8EC;
  overflow-x: hidden;
}

.transaction-sidepanel-header {
  height: 96px;
  border-bottom: 1px solid #E6E8EC;
  padding: 40px 32px 32px 32px;
}

.transaction-sidepanel-content {
  padding: 32px 72px 112px 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100% - 96px);
  overflow-y: auto;
}

.transaction-sidepanel-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 96px);
}

.transaction-card {
  border-left: 1px solid #E6E8EC;
  border-top: 1px solid #E6E8EC;
  border-right: 1px solid #E6E8EC;
  padding: 16px;
  display: flex;
  gap: 12px;
}

.transaction-card:first-of-type {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.transaction-card:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom: 1px solid #E6E8EC;
}

.paid-label {
  font-weight: 500;
  color: black;
  background-color: #b4e7cf;
  border-radius: 5px;
  padding: 2px 4px;
}

.not-paid-label {
  font-weight: 500;
  color: black;
  background-color: #ffb4b6;
  border-radius: 5px;
  padding: 2px 4px;
  width: fit-content;
}

.payment-status-button-container {
  cursor: pointer;
  min-width: 80px;
  width: 80px;
  position: relative;
}

.default-label-style {
  white-space: nowrap;
  text-overflow: ellipsis;

  border-radius: 4px;
  border-width: 0;
  padding: 0 4px;
  flex-direction: row;
  display: inline-flex;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.Sale {
  color: #0aaf60 !important;
  background: #f2ffe0;
}

.LeaseOut {
  color: #f81ca0 !important;
  background: #fff2f7;
}

.Purchase {
  color: #4c8dd9 !important;
  background: #dbe8f7;
}

.AquireLease {
  color: #ff5c01 !important;
  background: #fff0d4;
}

.Referral {
  color: #a89963 !important;
  background: #f4f5f7;
}
</style>